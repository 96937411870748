import { render, staticRenderFns } from "./PhoneType.vue?vue&type=template&id=1fdaf423&"
import script from "./PhoneType.vue?vue&type=script&lang=js&"
export * from "./PhoneType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/xbill/public_html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1fdaf423')) {
      api.createRecord('1fdaf423', component.options)
    } else {
      api.reload('1fdaf423', component.options)
    }
    module.hot.accept("./PhoneType.vue?vue&type=template&id=1fdaf423&", function () {
      api.rerender('1fdaf423', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/base/modal/CustomField/types/PhoneType.vue"
export default component.exports