import { render, staticRenderFns } from "./PaymentsTab.vue?vue&type=template&id=484b88ba&"
import script from "./PaymentsTab.vue?vue&type=script&lang=js&"
export * from "./PaymentsTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/xbill/public_html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('484b88ba')) {
      api.createRecord('484b88ba', component.options)
    } else {
      api.reload('484b88ba', component.options)
    }
    module.hot.accept("./PaymentsTab.vue?vue&type=template&id=484b88ba&", function () {
      api.rerender('484b88ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/views/settings/customization-tabs/PaymentsTab.vue"
export default component.exports